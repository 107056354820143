import { graphql, useStaticQuery } from 'gatsby'
import performance from '../../content/performance'
import CardLink from '../CardLink/CardLink'
import './PerformanceNews.scss'

const { news_section } = performance

const PerformanceNews = () => {
  const data = useStaticQuery<Queries.PerformanceArticlesQuery>(query)

  return (
    <section className="PerformanceNews">
      <div className="section-lg flex flex-col gap-20 md:gap-10">
        <h2 className="text-3xl text-center">{news_section.title}</h2>
        <div className="PerformanceNews__Card__container">
          {data.pennylaneCloud.search.items.map((post, i) => (
            <CardLink
              className="PerformanceNews__Card"
              key={`performance-news-${post.id}-${i}`}
              link={`/blog/${post.slug}`}
              icon={post.thumbnail || ''}
              alt=""
              title={post.title}
              linkText="Read more"
            />
          ))}
        </div>
      </div>
    </section>
  )
}

const query = graphql`
  query PerformanceArticles {
    pennylaneCloud {
      search(
        input: {
          contentTypes: [BLOG]
          specificFilters: {
            blogFilters: { categories: ["high-performance computing"] }
          }
          sorts: { desc: true, field: publishedAt }
        }
        page: { limit: 3, offset: 0 }
      ) {
        totalItems
        items {
          ... on pennylaneCloud_Content {
            id
            title
            slug
            thumbnail
            categories
          }
        }
      }
    }
  }
`

export default PerformanceNews
